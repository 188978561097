import "../style/shared.css"
import React, {useState} from "react"; 

function Dashboard() {
    return (
        <>
            <p>test</p>
        </>
    )
}

export default Dashboard;