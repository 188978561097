import './../style/checkbox.css'

function Checkbox(parent) {
    return (
        <>
            <label id={parent.id} className="checkbox-container">
                <input id={parent.id+"-input"} type="checkbox"/>
                <span className="custom-checkbox"></span>
                {parent.children}
            </label>
        </>
    )
}

export default Checkbox;
