import Background from "../components/Background"; 
import '../style/shared.css'
import '../style/login.css'
import Checkbox from "../components/Checkbox"; 
import React, { useState } from "react";

const url = "https://api.tickitly.de"

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showWarning, setShowWarning] = useState(false);
    
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }
    
    const OnLogin = () => {
        fetch(`${url}/login?email=${email}&password=${password}`, {
            method: "GET",
            contentType: "application/json; charset=utf-8",
        }).then(res => res.json()).then(data => {
            setShowWarning(data.status != 200)
            if (data.status == 200) {
                window.open("/dashboard", "_self")
            }
        }).catch(err => console.log(err));
    }
    
    return (
        <>
            <h1 className="title">tickitly</h1>
            <Background/>
            <div className="login-container">
                {showWarning && <p id="login-wrong-credentials">Wrong credentials, try again.</p>}

                <input onChange={handleEmailChange} id="login-email" type="text" placeholder="E-Mail"/>
                <input onChange={handlePasswordChange} id="login-pw" type="password" placeholder="Password"/>
                <p className="login-forgot">Reset password</p>
                <br/>
                <Checkbox id="login-checkbox">Remember me</Checkbox>

                <br/>
                <button onClick={OnLogin} className="login-button">LOGIN</button>
                <br/>
                <p className="login-infotext">If you need any help please contact our team.<br/>info@tickitly.de</p>
            </div>
        </>
    )
}

export default Login;