import logo from './logo.svg';
import './style/App.css';
import Login from './sites/Login';
import Dashboard from './sites/Dashboard';
import {Routes,  Route} from "react-router-dom";

function App() {
  return (
      <div>
          <Routes>
              <Route path="/" element={<Login />}></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
          </Routes>
      </div>
  );
}

export default App;
